<template>
  <div class="conten" @click="showEventi()">
    <div>
      <el-container class="mainBox">
        <el-header class="header flex_jcSB_aiC">
          <div class="flex_aiC headerleft">
            <img class="oneImgs" :src="logoImgs2" alt />
          </div>
          <!-- <div v-if="ym" class="flex_aiC headerleft">
            <img class="logoImg2" src="../assets/img/xhylogo.png" alt />
          </div> -->
          <!-- <div v-else class="flex_aiC headerleft">
            <img
              v-if="isShowImg"
              :class="!isShowTitle ? 'logoImg_zt' : 'logoImg'"
              :src="logoImgs"
              alt
            />
            <h3 v-if="isShowTitle">{{ MainTitle }}</h3>
          </div> -->

          <div class="headerbox">
            <div class="topTabs">
              <div class="rightTips">
                <el-dropdown @command="handleCommandTabs">
                  <el-button class="righttagICon">
                    <i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item command="a">刷新</el-dropdown-item>
                    <el-dropdown-item command="b">关闭全部</el-dropdown-item>
                    <el-dropdown-item command="c">关闭右侧</el-dropdown-item>
                    <el-dropdown-item command="d">关闭左侧</el-dropdown-item>
                    <el-dropdown-item command="e">关闭其他</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
              <el-tabs
                class="leftTap"
                v-model="editableTabsValue"
                @tab-click="goRoute"
                type="card"
                @tab-remove="removeTab"
                closable
              >
                <template v-for="(item, index) in editableTabs">
                  <el-tab-pane
                    :key="index"
                    :label="item.name"
                    :name="item.path"
                  ></el-tab-pane>
                </template>
              </el-tabs>
            </div>
          </div>

          <div class="flex_aiC top_right">
            <!-- <img class="icon2" src="../assets/img/index01.svg" alt="" />
                                                <div class="right_heard">
                                                  <img class="icon2" src="../assets/img/index01.svg" alt="" />
            </div>-->
            <el-button
              type="text"
              style="margin-right: 0px; color: #fff"
              v-if="hasPermiss('xx_Xinxi:query')"
              @click.stop="goMessageAdmin"
              >消息({{ unReadMsgNumber }})</el-button
            >
            <el-button
              type="text"
              style="margin-right: 0px; color: #fff"
              @click.stop="personal"
              >{{ userdata.employeeName || "个人中心" }}</el-button
            >
          </div>
        </el-header>
        <el-container>
          <!-- 左边 -->
          <el-aside class="aside" :width="widthstyle">
            <el-radio-group v-model="isCollapse" style="margin-bottom: 0px">
              <el-button @click="openbut">{{ state }}</el-button>
            </el-radio-group>
            <el-menu
              :unique-opened="true"
              :collapse="isCollapse"
              :default-openeds="openeds"
              :default-active="defaultActive"
              router
            >
              <template v-for="(a_item, a_index) in menuList">
                <el-submenu
                  v-if="a_item.children && a_item.children.length > 0"
                  :index="a_index + ''"
                  :key="a_index"
                >
                  <template slot="title">
                    <img
                      v-if="a_item.menuId == '7'"
                      class="imgstyle"
                      :src="kzmbIcon"
                    />
                    <img
                      v-if="a_item.menuId == '9'"
                      class="imgstyle"
                      :src="yeWuIcon"
                    />
                    <img
                      v-if="a_item.menuId == '10'"
                      class="imgstyle"
                      :src="caiWuIcon"
                    />
                    <img
                      v-if="a_item.menuId == '12'"
                      class="imgstyle"
                      :src="huiYuanIcon"
                    />
                    <img
                      v-if="a_item.menuId == '13'"
                      class="imgstyle"
                      :src="kzmbIcon"
                    />
                    <img
                      v-if="a_item.menuId == '14'"
                      class="imgstyle"
                      :src="xiTongIcon"
                    />
                    <!-- <img v-else class="imgstyle" :src="kzmbIcon" /> -->
                    <span>{{ a_item.menuName }}</span>
                  </template>
                  <template v-for="(b_item, b_index) in a_item.children">
                    <el-submenu
                      :key="b_index"
                      :index="a_index + '-' + b_index"
                      v-if="b_item.children && b_item.children.length > 0"
                    >
                      <template slot="title">
                        <i class="el-icon-folder-add"></i>
                        <span>{{ b_item.menuName }}</span>
                      </template>
                      <el-menu-item
                        v-for="c_item in b_item.children"
                        :key="c_item.jumpUrl"
                        :index="c_item.jumpUrl"
                        >{{ c_item.menuName }}</el-menu-item
                      >
                    </el-submenu>
                    <el-menu-item
                      v-else
                      :key="b_item.jumpUrl"
                      :index="b_item.jumpUrl"
                    >
                      <span slot="title">{{ b_item.menuName }}</span>
                    </el-menu-item>
                  </template>
                </el-submenu>
                <el-menu-item v-else :key="a_index" :index="a_item.jumpUrl">
                  <!-- <i class="el-icon-postcard"></i> -->
                  <img
                    v-if="a_item.menuId == '7'"
                    class="imgstyle"
                    :src="kzmbIcon"
                  />
                  <img
                    v-if="a_item.menuId == '9'"
                    class="imgstyle"
                    :src="yeWuIcon"
                  />
                  <img
                    v-if="a_item.menuId == '10'"
                    class="imgstyle"
                    :src="caiWuIcon"
                  />
                  <img
                    v-if="a_item.menuId == '12'"
                    class="imgstyle"
                    :src="huiYuanIcon"
                  />
                  <img
                    v-if="a_item.menuId == '13'"
                    class="imgstyle"
                    :src="kzmbIcon"
                  />
                  <img
                    v-if="a_item.menuId == '14'"
                    class="imgstyle"
                    :src="xiTongIcon"
                  />
                  <!-- <img v-else class="imgstyle" :src="kzmbIcon" /> -->
                  <span slot="title">{{ a_item.menuName }}</span>
                </el-menu-item>
              </template>
            </el-menu>
          </el-aside>
          <!-- 右边 -->
          <el-main class="main">
            <div v-if="show == true">
              <div class="PersonalCenter" @click.stop="StopBubbling">
                <div class="libox">
                  <div class="liimg">
                    <i class="el-icon-s-flag"></i>
                  </div>
                  <div class="liright">
                    <div class="liboxtop">所属部门:</div>
                    <div class="liboxbot">{{ userdata.departmentName }}</div>
                  </div>
                </div>
                <div class="libox">
                  <div class="liimg">
                    <i class="el-icon-location-outline"></i>
                  </div>
                  <div class="liright">
                    <div class="liboxtop">当前仓库:</div>
                    <div class="liboxbot">{{ userdata.storageName }}</div>
                  </div>
                </div>
                <!-- <div class="libox" @click.stop="informationbut">
                  <div class="liimg">
                    <i class="el-icon-user"></i>
                  </div>
                  <div class="liright">
                    <div class="liboxbot">我的信息</div>
                  </div>
                </div>-->

                <div class="libox" @click.stop="modifyPwdbut">
                  <div class="liimg">
                    <i class="el-icon-edit-outline"></i>
                  </div>
                  <div class="liright">
                    <div class="liboxbot">修改密码</div>
                  </div>
                </div>
                <div class="libox" @click.stop="outLogin">
                  <div class="liimg">
                    <i class="el-icon-remove-outline"></i>
                  </div>
                  <div class="liright">
                    <div class="liboxbot">退出登录</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- <div
              v-if="WarehouseShow == true"
              @mouseleave="Warehousedisappear"
              @mouseenter="MoveInWarehouse"
            >
              <div class="Warehouse">
                <div class="WarehouseDetails" @click="SwitchWarehouse">
                  海南仓库
                </div>
                <div class="WarehouseDetails" @click="SwitchWarehouse">
                  新加坡仓库
                </div>
              </div>
            </div>-->

            <el-dialog
              title="修改密码"
              :visible.sync="modifyPwd"
              :before-close="eliminate"
            >
              <div class="pwdform">
                <div class="singleinp">
                  <div @click="QueryNumber = true" class="singleinpleft">
                    原密码:
                  </div>
                  <div class="singleinpright">
                    <el-input
                      type="password"
                      class="input"
                      autocomplete="off"
                      v-model="pwdlist.oldPassword"
                      placeholder="请输入原密码"
                    ></el-input>
                  </div>
                </div>
                <div class="singleinp">
                  <div @click="QueryNumber = true" class="singleinpleft">
                    新密码:
                  </div>
                  <div class="singleinpright">
                    <!-- <el-input type=""></el-input> -->
                    <el-input
                      type="password"
                      class="input"
                      autocomplete="off"
                      v-model="pwdlist.newPasswpord"
                      placeholder="请输入新密码"
                    ></el-input>
                  </div>
                </div>
                <div class="singleinp">
                  <div @click="QueryNumber = true" class="singleinpleft">
                    确认密码:
                  </div>
                  <div class="singleinpright">
                    <el-input
                      type="password"
                      class="input"
                      autocomplete="off"
                      v-model="pwdlist.confirmPassword"
                      placeholder="请再次确定新密码"
                    ></el-input>
                  </div>
                </div>
              </div>
              <div class="footbox">
                <el-button size="small" type="warning" @click="ChangePassword"
                  >确定</el-button
                >
                <el-button size="small" @click="eliminate">取消</el-button>
              </div>
            </el-dialog>

            <el-dialog title="我的信息" :visible.sync="information">
              <div class="Userinfo">
                <div class="Userinfoli">
                  <div class="Userinfolileft">所属部门 :</div>
                  <div class="Userinfoliright">
                    {{ userdata.departmentName }}
                  </div>
                </div>
                <div class="Userinfoli">
                  <div class="Userinfolileft">所属仓库 :</div>
                  <div class="Userinfoliright">{{ userdata.storageName }}</div>
                </div>
                <div class="Userinfoli">
                  <div class="Userinfolileft">用户编号 :</div>
                  <div class="Userinfoliright">{{ userdata.jobNumber }}</div>
                </div>
                <div class="Userinfoli">
                  <div class="Userinfolileft">用户名称 :</div>
                  <div class="Userinfoliright">{{ userdata.employeeName }}</div>
                </div>
              </div>
            </el-dialog>
            <!-- 导航 -->
            <!-- 内容 -->
            <div class="mainConten" ref="mainConten">
              <keep-alive>
                <!-- 需要缓存的视图组件 -->
                <router-view v-if="$route.meta.keepAlive"></router-view>
              </keep-alive>
              <!-- 不需要缓存的视图组件 -->
              <router-view v-if="!$route.meta.keepAlive"></router-view>
            </div>
          </el-main>
        </el-container>
      </el-container>
    </div>
  </div>
</template>

<script>
import { apiList, Api } from "@/assets/js/api";
import tools from "@/assets/js/tool";
import kzmbIcon from "../assets/img/meunImg/kongZhiMianBan.svg";
import yeWuIcon from "../assets/img/meunImg/yeWu.svg";
import caiWuIcon from "../assets/img/meunImg/caiWu.svg";
import huiYuanIcon from "../assets/img/meunImg/huiYuan.svg";
import xiTongIcon from "../assets/img/meunImg/xiTong.svg";
import bamiLogo from "../assets/img/index01.svg"; //八米logo
import ztLogo from "../assets/img/ztLogo.png"; //中坦国际logo
export default {
  inject: ["reload"],
  data() {
    return {
      logoImgs2: "",
      unReadMsgNumber: 0, //未读消息数量
      userdata: [], //个人信息
      // //会缓存的路由  白名单
      includeArr: "",
      // //不缓存的路由  黑名单
      excludeArr: "",
      condition: false,
      WarehouseShow: false,
      show: false,
      state: "收起",
      isCollapse: false,
      widthstyle: "200px",
      iconsList: {},
      //侧板菜单栏
      defaultActive: "/controlPanel",
      mianbanpath: "/controlPanel",
      menuList: [],
      openeds: ["0"], //菜单默认展开第一个
      uniOpen: true, //侧边只能展开一个，false可以展开多个

      editableTabsNameList: [], //当前导航栏列表路由的名称
      editableTabsValue: "", //当前激活的导航
      timesr: "",
      information: false,
      modifyPwd: false,
      pwdlist: {
        oldPassword: "",
        newPasswpord: "",
        confirmPassword: "",
      },
      clientHeight: "", //高度
      bodyWidth: "", //宽度

      kzmbIcon: "",
      yeWuIcon: "",
      caiWuIcon: "",
      huiYuanIcon: "",
      xiTongIcon: "",
      ym: false,
      logoImgs: bamiLogo,
      MainTitle: "",
      isShowTitle: true,
      isShowImg: true,
    };
  },
  created() {
    let DomainName = tools.getDomainName();
    if (
      DomainName == "dev.b9.admin.8bami.com" ||
      DomainName == "xhy.dg.admin.8bami.com"
    ) {
      // 鑫华远logo
      this.ym = true;
    }
    if (DomainName == "admin.ywhhwl.com") {
      document.title = "中坦国际TMS";
      this.MainTitle = "中坦国际TMS";
      this.logoImgs = ztLogo;
      this.isShowImg = true; //不显示图片
      this.isShowTitle = false; //显示文字
    } 

    this.kzmbIcon = kzmbIcon; //控制面板图片
    this.yeWuIcon = yeWuIcon; //业务管理图片
    this.caiWuIcon = caiWuIcon; //财务图片
    this.huiYuanIcon = huiYuanIcon; //会员
    this.xiTongIcon = xiTongIcon; //系统管理
    if (window.sessionStorage.editableTabs) {
      this.editableTabs = JSON.parse(window.sessionStorage.editableTabs);
    } else {
      this.editableTabs = [
        {
          name: this.$route.meta.title,
          path: this.$route.path,
        },
      ];
      window.sessionStorage.editableTabs = JSON.stringify(this.editableTabs);
    }
    this.defaultActive = this.$route.fullPath;
    this.editableTabsValue = this.$route.path;
    let arrLength = this.editableTabs.length;
    for (let f = 0; f < arrLength; f++) {
      this.editableTabsNameList.push(this.editableTabs[f].name);
    }

    let usermenuListList =
      JSON.parse(sessionStorage.getItem("usermenuList")) || [];
    this.menuList = usermenuListList;
    if (usermenuListList.length < 1) {
      this.getLoginUserRoleMenuPerList(); //获取登录用户的菜单权限
    }

    // this.getAdminMenus();// 获取菜单

    // this.getStatusValList("package_v1.package_status"); //包裹状态
    // this.getStatusValList("bill_rejection_v1.handle_status"); //拒签状态
    // this.getStatusValList("bill_rejection_v1.rejection_status"); //拒签类型
    // this.getStatusValList("bill_inspection_v1.check_status"); //包裹状态
    // this.getStatusValList("finance_in_record_v1.approve_status"); //审批状态
    // this.getStatusValList("finance_in_record_v1.writeoff_status"); //核销状态
    // this.getStatusValList("finance_in_record_v1.object_status"); //结算对象类型
    // this.getStatusValList("finance_in_record_v1.document_status"); //单据类型
    // this.getStatusValList("bill_pack_v1.pack_status"); //打包状态
    // this.getStatusValList("package_team_v1.team_status"); //拼团类型
    // this.getStatusValList("file_all_v2.document_status"); //文件来源单据类型
    // this.getStatusValList("customer_agent_v1.settlement_status"); //渠道结算类型
    // bill_transfer_v1.transfer_status  //充值类型
    // bill_kf_track_record_v2.document_status  //客服跟踪单据类型

    // this.getStorageList(); //仓库列表
    // this.storageCargoareaList(); //货区列表
    // this.storageCargoareaFrameList(); //货架列表

    // 登录用户信息
    Api.personalInfo().then((res) => {
      this.userdata = res.data.result || [];
    });
    // // 获取logo
    // Api.getLogo().then((res) => {
    //   this.userdata = res.data.result || [];
    // });
    this.unReadMessage();

    // 左上角logo
    this.$API.getSystemVI().then((res) => {
      let datas = res.data.result || "";
      document.title = datas.tenantSystemName;
      // 租户登陆页logo
      if (datas.tenantLogoUrl) {
        this.logoImgs2 = datas.tenantLogoUrl || "";
      }
    });
  },
  mounted() {},
  watch: {
    $route() {
      let num = this.editableTabsNameList.indexOf(this.$route.meta.title);
      if (num === -1) {
        this.editableTabsNameList.push(this.$route.meta.title);
        this.editableTabs.push({
          name: this.$route.meta.title,
          path: this.$route.path,
        });
      }
      this.editableTabsValue = this.$route.path;
      if (this.defaultActive == "/controlPanel") {
        this.defaultActive = this.$route.fullPath;
      }
      // this.defaultActive = this.$route.fullPath;
      window.sessionStorage.editableTabs = JSON.stringify(this.editableTabs);
    },
  },
  methods: {
    // 跳转消息管理
    goMessageAdmin() {
      this.$router.push({
        path: "/systemManagement/advancedSetting/MessageManagement",
      });
    },
    // 消息未读总数
    unReadMessage() {
      Api.unReadMessageApi(this.pwdlist).then((res) => {
        if (res.data.status == "success") {
          this.unReadMsgNumber = res.data.result.unreadCount;
        }
      });
    },
    //修改密码
    ChangePassword() {
      let _this = this;
      Api.updatePassword(this.pwdlist).then((res) => {
        if (res.data.status == "success") {
          _this.$message.success("修改成功");
          setTimeout(() => {
            _this.eliminate();
          }, 1000);
        }
      });
    },
    //关闭密码弹窗
    eliminate() {
      this.modifyPwd = false;
      this.pwdlist = {
        oldPassword: "",
        newPasswpord: "",
        confirmPassword: "",
      };
    },
    // 登录用户的全部菜单和权限
    getLoginUserRoleMenuPerList() {
      Api.getLoginUserRoleMenuPerList().then((res) => {
        if (res.data.status == "success") {
          let allMenuList = res.data.result.menuVoList || [];
          let allPmList = res.data.result.permissionList || [];

          this.menuList = this.diGuiGetMeun(allMenuList, "-1") || [];

          let permissList = [];
          allPmList.forEach((item) => {
            // if(item)
            permissList.push(item.name);
          });
          if (permissList.length > 0) {
            window.sessionStorage.setItem(
              "btnPermiss",
              JSON.stringify(permissList)
            );
          }

          if (this.menuList.length > 0) {
            window.sessionStorage.setItem(
              "usermenuList",
              JSON.stringify(this.menuList)
            );
          }
        }
      });
    },

    // 展开收起左边菜单
    openbut() {
      this.isCollapse = !this.isCollapse;
      if (this.isCollapse == true) {
        this.state = "展开";
        this.widthstyle = "60px";
      } else {
        this.state = "收起";
        this.widthstyle = "200px";
      }
    },
    //货架列表
    storageCargoareaFrameList() {
      Api.storageCargoareaFrameList().then((res) => {
        let huojiaList = res.data.result || [];
        window.localStorage.setItem("huojiaList", JSON.stringify(huojiaList));
      });
    },
    //货区列表
    storageCargoareaList() {
      Api.storageCargoareaList().then((res) => {
        let huoquList = res.data.result || [];
        window.localStorage.setItem("huoquList", JSON.stringify(huoquList));
      });
    },
    //获取仓库下拉列表
    getStorageList() {
      Api.getStorageList().then((res) => {
        let cangkuList = res.data.result || [];
        window.localStorage.setItem("cangkuList", JSON.stringify(cangkuList));
      });
    },
    // 获取状态展示值
    getStatusValList(status) {
      let param = {
        tableAndFieldName: status,
      };

      Api.getStatusValList(param).then((res) => {
        // 包裹状态
        if (status == "package_v1.package_status") {
          let packageStatusList = res.data.result || [];
          window.localStorage.setItem(
            "packageStatusList",
            JSON.stringify(packageStatusList)
          );
        }
        // 拒收单拒收状态
        if (status == "bill_rejection_v1.handle_status") {
          let rejectionStatusList = res.data.result || [];
          window.localStorage.setItem(
            "rejectionStatusList",
            JSON.stringify(rejectionStatusList)
          );
        }
        // 验货单验货状态
        if (status == "bill_inspection_v1.check_status") {
          let inspectionStatusList = res.data.result || [];
          window.localStorage.setItem(
            "inspectionStatusList",
            JSON.stringify(inspectionStatusList)
          );
        }
        // 审核状态
        if (status == "finance_in_record_v1.approve_status") {
          let approveStatusList = res.data.result || [];
          window.localStorage.setItem(
            "approveStatusList",
            JSON.stringify(approveStatusList)
          );
        }
        // 核销状态
        if (status == "finance_in_record_v1.writeoff_status") {
          let writeoffStatusList = res.data.result || [];
          window.localStorage.setItem(
            "writeoffStatusList",
            JSON.stringify(writeoffStatusList)
          );
        }
        // 结算对象类型
        if (status == "finance_in_record_v1.object_status") {
          let objectStatusList = res.data.result || [];
          window.localStorage.setItem(
            "objectStatusList",
            JSON.stringify(objectStatusList)
          );
        }
        // 单据类型
        if (status == "finance_in_record_v1.document_status") {
          let documentStatusList = res.data.result || [];
          window.localStorage.setItem(
            "documentStatusList",
            JSON.stringify(documentStatusList)
          );
        }
        // 打包状态
        if (status == "bill_pack_v1.pack_status") {
          let packStatusList = res.data.result || [];
          window.localStorage.setItem(
            "packStatusList",
            JSON.stringify(packStatusList)
          );
        }
      });
    },
    //导航操作
    handleCommandTabs(m) {
      let currPath = this.$route.fullPath;
      let tabArr = this.editableTabs;
      let currIdx = 0;
      let arrLength = tabArr.length;
      for (var i = 0; i < tabArr.length; i++) {
        if (currPath === tabArr[i].path) {
          currIdx = i;
        }
      }

      if (m == "a") {
        //刷新
        this.reload();
      } else if (m == "b") {
        //关闭全部
        this.editableTabs = [];
        this.editableTabs.push({
          name: "控制面板",
          path: "/controlPanel",
        });
        this.editableTabsNameList.push(this.editableTabs[0].name);
        this.defaultActive = this.editableTabs[0].path;
        this.editableTabsValue = this.editableTabs[0].path;
        window.sessionStorage.editableTabs = JSON.stringify(this.editableTabs);
        this.$router.push({
          path: "/controlPanel",
        });
        this.reload();
      } else if (m == "c") {
        //关闭右侧
        this.editableTabs.splice(currIdx + 1, arrLength - (currIdx + 1));
        this.defaultActive = this.editableTabs[currIdx].path;
        this.editableTabsValue = this.editableTabs[currIdx].path;
        window.sessionStorage.editableTabs = JSON.stringify(this.editableTabs);
        this.reload();
      } else if (m == "d") {
        //关闭左侧
        if (currIdx == 0) {
          return;
        }

        this.editableTabs.splice(1, currIdx - 1);

        /*  this.defaultActive = this.editableTabs[currIdx].path;
                                this.editableTabsValue = this.editableTabs[currIdx].path; */
        window.sessionStorage.editableTabs = JSON.stringify(this.editableTabs);
        this.reload();
      } else if (m == "e") {
        //关闭其他
        let arrE = [];
        if (currPath == "/controlPanel") {
          arrE = [];
        } else {
          arrE.push({
            name: "控制面板",
            path: "/controlPanel",
          });
        }

        arrE.push({
          name: this.editableTabs[currIdx].name,
          path: this.editableTabs[currIdx].path,
        });
        this.editableTabs = arrE;
        this.defaultActive = arrE[0].path;
        this.editableTabsValue = arrE[0].path;
        window.sessionStorage.editableTabs = JSON.stringify(this.editableTabs);
        this.reload();
      }
      this.editableTabsNameList = [];
      for (var q = 0; q < this.editableTabs.length; q++) {
        this.editableTabsNameList.push(this.editableTabs[q].name);
      }
    },
    //获取菜单
    // getAdminMenus() {
    //   Api.getAdminMenus().then((res) => {
    //     let menulist = res.data.result || [];
    //     this.diGuiGetMeun(menulist, "-1") || [];
    //   });
    // },
    //递归获取数据
    diGuiGetMeun(data, parentId) {
      let that = this;
      let tree = [];
      let temp;
      data.forEach((item) => {
        if (item.parentId == parentId) {
          let obj = item;
          obj.menuName = item.menuName;
          obj.children = [];
          /////
          if (that.common.serve_s == "dev" && !tools.NoHuoMao()) {
            if (item.menuId == "24") {
              // 24==申请打包旧版
              // 测试环境,除了货猫,其他的不显示包裹入库原来的申请打包页面
            } else {
              temp = this.diGuiGetMeun(data, item.menuId);
              if (temp.length > 0) {
                obj.children = temp;
              }
              tree.push(obj);
            }
          } else {
            // 113==申请打包新版,114==出入库日志,115==合包列表
            if (
              (item.menuId == "113" ||
                item.menuId == "114" ||
                item.menuId == "115") &&
              !tools.NoHuoMao()
            ) {
              // 正式环境,,货猫不显示新加的菜单
            } else if (item.menuId == "24" && tools.NoHuoMao()) {
              //  除了货猫,其他的不显示包裹入库原来的申请打包页面
            } else {
              temp = this.diGuiGetMeun(data, item.menuId);
              if (temp.length > 0) {
                obj.children = temp;
              }
              tree.push(obj);
            }
          }

          // 原来
          // temp = this.diGuiGetMeun(data, item.menuId);
          // if (temp.length > 0) {
          //   obj.children = temp;
          // }
          // tree.push(obj);
        }
      });
      //排序
      for (var i = 0; i < tree.length - 1; i++) {
        for (var j = 0; j < tree.length - 1 - i; j++) {
          if (tree[j].sort > tree[j + 1].sort) {
            let temp = tree[j + 1];
            tree[j + 1] = tree[j];
            tree[j] = temp;
          }
        }
      }

      return tree;
    },
    personal() {
      this.show = !this.show;
    },
    // 刷新页面
    shuaXin() {
      this.reload();
    },
    //关闭标签
    removeTab(targetName) {
      if (targetName == "/controlPanel") {
        return;
      }

      this.editableTabsNameList = [];
      let aLength = this.editableTabs.length;
      if (aLength == 1) {
        this.editableTabs = [];
        this.editableTabs.push({
          name: "控制面板",
          path: "/controlPanel",
        });
        this.editableTabsNameList.push(this.editableTabs[0].name);
        // this.defaultActive = this.editableTabs[0].path;
        this.editableTabsValue = this.editableTabs[0].path;
        this.$router.push({
          path: this.editableTabs[0].path,
        });
        return;
      }
      let b = 0;

      let CopyArr = JSON.parse(JSON.stringify(this.editableTabs));

      for (let a = 0; a < CopyArr.length; a++) {
        if (CopyArr[a].path === targetName) {
          b = a;
        } else {
          this.editableTabsNameList.push(CopyArr[a].name);
        }
      }
      CopyArr.splice(b, 1);

      var cIndex = 0;
      if (b > 0) {
        cIndex = b - 1;
      }
      for (var i = 0; i < CopyArr.length; i++) {
        if (CopyArr[i].path == this.$route.fullPath) {
          cIndex = i;
        }
      }
      // this.defaultActive = CopyArr[cIndex].path;
      this.editableTabsValue = CopyArr[cIndex].path;
      this.$router.push(CopyArr[cIndex].path);
      this.editableTabs = CopyArr;
      window.sessionStorage.editableTabs = JSON.stringify(this.editableTabs);
      this.shuaXin();
    },
    //菜单点击跳转
    goRoute(link) {
      this.$router.replace(link.name);
    },
    //仓库信息显示
    Warehousebut() {
      clearTimeout(this.timesr);
      this.WarehouseShow = true;
    },
    //仓库隐藏
    move() {
      this.condition = false;
      this.timesr = setTimeout(() => {
        this.WarehouseShow = false;
      }, 1000);
    },
    //子页面鼠标移入
    MoveInWarehouse() {
      this.WarehouseShow = true;
      clearTimeout(this.timesr);
    },
    //子页面鼠标移开
    Warehousedisappear() {
      this.timesr = setTimeout(() => {
        this.WarehouseShow = false;
      }, 1000);
    },
    showEventi() {
      if (this.show) {
        this.show = false;
        this.WarehouseShow = false;
      }
    },
    //切换仓库
    SwitchWarehouse() {
      this.$confirm("是否切换为该仓库?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message.success("切换成功!");
      });
      this.WarehouseShow = false;
      this.show = false;
    },
    //我的信息弹窗
    informationbut() {
      this.WarehouseShow = false;
      this.show = false;
      this.information = true;
    },
    //修改密码
    modifyPwdbut() {
      this.WarehouseShow = false;
      this.show = false;
      this.modifyPwd = true;
    },
    StopBubbling() {},
    //仓库信息影藏
    // 退出登录
    outLogin() {
      let that = this;
      this.$confirm("是否退出本次登录?", "提示")
        .then(() => {
          window.localStorage.removeItem("UserKey");
          window.sessionStorage.clear();
          // this.$message.success("即将退出登录");
          setTimeout(() => {
            that.$router.push({
              path: "/login",
            });
          }, 1000);
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">

.el-header {
  padding:  0 20px 0 0;
}

.conten {
  background-color: #f2f2f2;

  .mainBox {
    width: 100%;
    height: 97vh;
    background-color: #fff;

    .header {
      background-color: #555555;
      color: #fff;
      position: relative;

      .logoImg {
        width: 28px;
        margin-right: 4px;
      }

      .logoImg_zt {
        width: 130px;
        margin: 0 auto;
      }

      .logoImg2 {
        width: 160px;
      }

      .top_right {
        width: 100px;
        height: 60px;
        display: flex;
        justify-content: flex-end;
        z-index: 999;

        .right_heard {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          overflow: hidden;
          border: 1px solid #fff;
          margin-left: 20px;
          box-sizing: border-box;

          img {
            width: 40px;
            height: 40px;
          }
        }
      }
    }

    .aside {
      background-color: #555555;
      height: calc(100vh - 61px);
      // background-color: #222;

      //

      .el-menu {
        background-color: #555555;
      }

      .el-submenu__title {
        color: #fff;
      }

      .el-menu-item {
        color: #fff;
      }

      .el-menu-item.is-active {
        color: #ef7b1a;
        background-color: #f2f2f2;
      }

      .el-submenu .el-menu {
        border: none;
        background-color: #f2f2f2;
      }
    }

    .main {
      height: calc(100vh - 61px);
      width: 100%;
      min-width: 780px;
      box-sizing: border-box;
      background-color: #fff;

      .mainConten {
        width: calc(100% - 20px);
        margin: 10px;
        box-sizing: border-box;
        background-color: #fff;
        height: calc(100vh - 125px);

        //overflow-y: scroll;
      }
    }
  }
}

.imgstyle {
  width: 16px;
  height: 16px;
  padding: 0 10px 0 0;
}

.el-tabs__header {
  margin-bottom: 0;
}

.el-submenu__title:hover {
  color: #ef7b1a !important;
  background-color: #f2f2f2 !important;
}

.el-menu-item-group__title {
  color: #ef7b1a;
}

.el-main {
  padding: 0;
}

.topTabs {
  bottom: 0;

  .el-tabs__item:nth-of-type(1) .el-icon-close {
    display: none;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item {
    color: #fff;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    background-color: #6d6a6a;
    color: #fff;
    border: 0;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item.is-active {
    border-bottom: none;
    background-color: #fff;
    color: #606266;
  }

  .el-tabs--card > .el-tabs__header .el-tabs__item {
    border: 0;
  }

  .el-tabs__item {
    height: 30px;
    line-height: 30px;
  }

  box-sizing: border-box;

  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .leftTap {
    flex: 1;
    overflow: hidden;
  }

  .rightTips {
    // width: 30px;
    // padding: 0 10px;
    height: 29px;
    line-height: 29px;
    cursor: pointer;
    background-color: #555555;

    width: 29px;
    border-right: 50%;

    .righttagICon {
      padding: 5px;
      border: none;
      background-color: #555555;
    }

    .el-button:focus,
    .el-button:hover {
      // border-color: #fff;
      background-color: #f2f2f2 !important;
    }
  }
}

.conten .mainBox .aside .el-submenu .el-menu {
  background-color: #555555;
}

.el-menu-item:focus,
.el-menu-item:hover {
  background-color: #f2f2f2;
  color: #ef7b1a !important;
}

.aside {
  height: calc(100vh - 61px);
  background-color: #555555;

  .el-button {
    background: #555555;
    border: 0;
  }
}

//表格内容为空时,提示语在左边
// .el-table__empty-block {
//   text-align: left;
// }
//表单收起时颜色
.el-submenu__title {
  color: #fff;
  background-color: #555555;
}

.el-menu-item {
  color: #fff;
  background-color: #555555;
}

.el-submenu__title:hover {
  color: #ef7b1a;
}

//仓库详情
.Warehouse {
  position: fixed;
  top: 63px;
  right: 209px;
  z-index: 100;
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 10px #cccccc;
  background-color: #fff;
  width: 150px;
  color: #333;
  font-size: 13px;

  .WarehouseDetails {
    display: flex;
    padding: 5px 15px;
    box-sizing: border-box;
  }

  .WarehouseDetails:hover {
    background-color: #ff8108;
  }
}

//个人中心样式
.PersonalCenter {
  font-size: 13px;
  position: fixed;
  top: 63px;
  right: 5px;
  min-width: 200px;
  max-width: 300px;
  border-radius: 5px;
  border: 1px solid #cccccc;
  box-shadow: 0px 0px 10px #cccccc;
  background-color: #fff;
  z-index: 100;
  display: flex;
  flex-direction: column;

  .libox:hover {
    background-color: #ff8108;
    color: #fff;
    cursor: pointer;
  }

  .libox {
    width: 100%;
    padding: 15px 8px;
    box-sizing: border-box;

    display: flex;
    border-bottom: 1px solid #cccccc;

    .liimg {
      width: 10%;
    }

    .liright {
      width: 90%;
      display: flex;

      .liboxtop {
        color: #999999;
        width: 60px;
      }

      .liboxbot {
        color: #333333;
      }
    }
  }
}

//个人信息样式
.Userinfo {
  width: 100%;
}

.Userinfoli {
  display: flex;
  width: 100%;
  padding: 12px 10%;
}

.Userinfolileft {
  width: 20%;
  display: flex;
  justify-content: flex-end;
}

.Userinfoliright {
  margin-left: 3%;
  color: #000;
}

.Userinfolibtn {
  margin-left: 5%;
  color: #5084e6;
  cursor: pointer;
}

.pwdform {
  width: 80%;
  margin: 0 auto;
  box-sizing: border-box;

  .singleinp {
    width: 80% !important;
    margin: 20px 0 !important;

    .el-input__inner {
      height: 28px;
    }
  }
}

.footbox {
  width: 96%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

.el-tabs__nav-next,
.el-tabs__nav-prev {
  line-height: 25px;
  color: #fff;
}

.aside .el-button {
  color: #ef7c1b;
}

// .headerleft {
//   width: 180px;
// }

.headerleft {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.oneImgs {
  width: 180px;
  max-height: 50px;
}

.headerbox {
  flex: 1;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
}

/* ::-webkit-scrollbar{
                     display: none;

                } */
</style>
